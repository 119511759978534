<template>
  <div class="profile-completed thank-you-page">
    <v-container>
      <v-card class="text-center">
        <CategoryTitle :category="category" />

        <p v-if="fidelityCard">
          {{ $t("profileConfirmed.fidelityCardText") }}
          <span class="font-weight-semibold"> {{ fidelityCard }} </span><br />
          <span>{{ $t("profileConfirmed.additionalText") }}</span>
        </p>
        <v-card-actions class="justify-center">
          <v-btn :to="{ name: 'Checkout' }" color="primary" large depressed>
            Concludi la spesa
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import categoryMixins from "~/mixins/category";
import CategoryTitle from "@/components/category/CategoryTitle";
import { mapState } from "vuex";

export default {
  name: "ProfileConfirmed",
  mixins: [categoryMixins],
  components: { CategoryTitle },
  computed: {
    ...mapState({
      fidelityCard: ({ cart }) => cart.cart.user.fidelityCard
    })
  }
};
</script>

<style></style>
